<template>
  <div ref="NoPermissionBox" class="p-box" :style="boxStyle">
      <div>
        <Iconfont name="icon-suo" :size="80" style="color: rgba(89, 140, 230, 1)" />
        <div>暂无权限查询</div>
      </div>
  </div>
</template>

<script>
import Iconfont from '@/components/Icon/Iconfont';
export default {
    data() {
        return {
            boxStyle: {}
        }
    },
    components: { 
        Iconfont
    },
    mounted() {
        this.$nextTick(function(){
            // console.log(this.$refs['NoPermissionBox'].offsetTop, document.documentElement.offsetHeight );
            // this.boxStyle = {
            //     height: document.documentElement.offsetHeight - this.$refs['NoPermissionBox'].offsetTop - 100 + 'px'
            // }
        })
    },
}
</script>

<style lang="scss" scoped>
    .p-box {
        position: relative;
        background-color: #fff;
        min-height: 200px;
        height: 100%;
        >div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            >div {
                color: rgba(102, 102, 102, 1);
                font-size: 14px;
            }
        }
    }
</style>
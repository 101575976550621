<template>
  <div class="form">
    <div class="filte-box"
      v-for="(item,ind) in groupCount"
      :key="ind">
      <div class="box-load">{{ item.title }}</div>
      <div class="box-conten">
        <div v-for="(item2, ind2) in item.list"
          :key="ind2+666"
          :class="{ 'check-options': ((ind2 == 0 && (parameterObj[item.backName] && parameterObj[item.backName].length == 0)) || (parameterObj[item.backName] && parameterObj[item.backName].includes(ind + '-' + ind2))), 'disabled': isDisabled(item2)}"
          @click="handleChangeSelect(item.backName, ind + '-' + ind2, item2.vehicleIds,item2.labelValue, showSumResult(item2))">{{ item2.label }}({{ item2.label == '全部' ? showAllSumResult(item) : showSumResult(item2, ind, ind2) }})</div>
      </div>
    </div>
    <div class="filte-box"
      style="line-height: 34px;margin-bottom: 0;">
      <div class="box-load">选择车组或车辆</div>
      <SelectGroupTree ref="selectGroupTree"
        :height="34"
        style="width:220px;"
        :multipleProp="multipleProp"
        normal
        :inputType="0"
        @on-select="onSelect"
        @on-confirm="Choice"
        @on-vehicle="onConfirm"
        @dbClick="dbConfirm"
        @clear="ClearSearchParams" />
      <el-button type="primary"
        style="margin-left: 24px;background-color: rgba(66, 120, 201, 1);border-color: rgba(66, 120, 201, 1);"
        icon="el-icon-search"
        size="mini"
        @click="handleSeve">搜索</el-button>
      <el-button v-if="isExport"
        type="primary"
        style="margin-left: 24px;background-color: rgba(66, 120, 201, 1);border-color: rgba(66, 120, 201, 1);"
        size="mini"
        @click="handleExport">
        <Iconfont :size="14"
          name="icon-daochu" /> 导出
      </el-button>
    </div>
  </div>
</template>

<script>
//选择车组
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';
import Iconfont from '@/components/Icon/Iconfont';
export default {
  props: {
    groupCount: {
      type: Array,
      default: function () {
        return [];
      },
      immediate: true
    },
    isExport: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    groupCount: function (val) {
      this.handleInit();
    }
  },
  data() {
    return {
      parameterObj: {},
      saveObj: {},
      loadingFlag: false,
      searchParams: {
        vehicleId: '',
        groupId: ''
      },
      multipleProp: {
        noGroup: true, // 是否可选车组
        groupCheckBox: true, //车组多选
        vehicleCheckBox: true, //车辆多选
      },
      allList: []
    };
  },
  activated() {
    if (this.$route.params.vehicle) {
      const that = this;
      this.$nextTick(function () {
        that.searchParams.vehicleId = that.$route.params.vehicle.V + '';
        that.$refs.selectGroupTree.selectVehicle = [that.$route.params.vehicle];
        //   that.$emit("Inquire", { vehicleIds: [that.$route.params.vehicle.V], isIntersection: false});
      });
    }
  },
  components: {
    SelectGroupTree,
    Iconfont
  },
  methods: {
    //车组车辆搜索框选择
    onSelect(node) {
      //先进行清空
      this.searchParams.vehicleId = "";
      this.searchParams.groupId = "";
      if (node.type == 2) {
        this.searchParams.vehicleId = node.vehicleId + '' || '';
        this.searchParams.groupId = node.vehicleId ? '' : node.groupId + '' || '';
      } else if (node.type == 1) {
        this.searchParams.vehicleId = node.vehicleId || '';
        this.searchParams.groupId = node.vehicleId ? '' : node.groupId + '' || '';
      };
      this.handleInit();
    },
    // 选择车辆事件
    onConfirm(node) {
      //先进行清空
      this.searchParams.vehicleId = "";
      this.searchParams.groupId = "";

      this.searchParams.vehicleId = node.vehicleId || "";
      this.searchParams.groupId = node.groupId.filter(val => { !node.vehicleId.some(item => item.groupId == val.groupId); }) || "";
      this.handleInit();
    },
    // 多选
    Choice(node) {
      console.log(node);
      //先进行清空
      this.searchParams.vehicleId = "";
      this.searchParams.groupId = "";
      this.searchParams.vehicleId = node.vehicleList.map(val => val.vehicleId).toString() || "";
      this.searchParams.groupId = node.groupList.map(val => val.groupId).toString() || "";
      this.handleInit();
    },
    // 选择车组
    dbConfirm(node) {
      console.log(node);
      this.searchParams.groupId = node.groupId;
      this.searchParams.vehicleId = "";
      this.handleInit();
    },
    //点击清除时，初始化车组车辆参数
    ClearSearchParams() {
      this.searchParams.vehicleId = "";
      this.searchParams.groupId = "";
    },
    // 选项变化
    async handleChangeSelect(type, ind, val, val2, num) {
      // console.log("type",type,ind,val,ind2)
      if (!num) {
        return false;
      }
      if (!this.parameterObj[type]) {
        this.handleInit();
        this.handleChangeSelect(type, ind, val);
      }
      // else if (ind2 == 0) {
      else if (ind.split('-')[1] == 0) {
        if (this.parameterObj[type].length > 0) {
          this.parameterObj[type] = [];
          this.saveObj[type] = [];
        }
      } else if (this.parameterObj[type].includes(ind)) {
        this.parameterObj[type].splice(this.parameterObj[type].indexOf(ind), 1);
        this.saveObj[type].splice(this.saveObj[type].indexOf((type == 'alarmLevel' || type == 'alarm') ? val2 : val), 1);
      } else if (val) {
        this.parameterObj[type].push(ind);
        this.saveObj[type].push((type == 'alarmLevel' || type == 'alarm') ? val2 : val);
      };
      await this.$nextTick();
      this.loadingFlag = false;
      this.$forceUpdate();
    },
    async handleSeve() {
      let val = '';
      let labelValue = '';
      let isIntersection = false;
      for (const key in this.saveObj) {
        if (this.saveObj[key].length > 0) {
          if (this.saveObj[key]) {
            isIntersection = true;
          }
          if (key == 'alarmLevel' || key == 'alarm') {
            if (labelValue) {
              labelValue += ",";
            }
            labelValue += this.saveObj[key].toString();
          } else {
            if (val) {
              val += ",";
            }
            val += this.saveObj[key].toString();
            // val.push(...this.saveObj[key]);
          }
        }
      };
      if (this.searchParams.vehicleId && val) {
        let vehicleList = this.searchParams.vehicleId.split(',');
        let vehicleList2 = val.split(',');
        val = vehicleList2.filter(item => vehicleList.includes(item)).toString();
      } else if (this.searchParams.vehicleId && !val) {
        val = await this.searchParams.vehicleId + '';
      }
      this.$emit("change", { vehicleIds: val, isIntersection: isIntersection, groupIds: this.searchParams.groupId, labelValue });
    },
    async handleExport() {
      // console.log("导出数据导出数据");
      let val = await this.searchParams.vehicleId + '';
      let labelValue = '';
      let isIntersection = false;
      for (const key in this.saveObj) {
        if (this.saveObj[key].length > 0) {
          if (this.saveObj[key]) {
            isIntersection = true;
          }
          if (key == 'alarmLevel' || key == 'alarm') {
            if (labelValue) {
              labelValue += ",";
            }
            labelValue += this.saveObj[key].toString();
          } else {
            if (val) {
              val += ",";
            }
            val += this.saveObj[key].toString();
          }
        }
      };
      this.$emit("export", { vehicleIds: val, isIntersection: isIntersection, groupIds: this.searchParams.groupId, labelValue });
    },
    handleInit() {
      if (this.groupCount && this.groupCount.length > 0) {
        this.groupCount.map(i => {
          this.parameterObj[i.backName] = [];
          this.saveObj[i.backName] = [];
        });
      }
    },
    isDisabled(item) {
      let flag = true;
      if (item && item.sumResult) {
        if (this.searchParams.vehicleId && item.vehicleIds) {
          let vehicleId = this.searchParams.vehicleId.split(',');
          let vehicleId2 = item.vehicleIds.split(',');
          let length = vehicleId.length + vehicleId2.length;
          if (length > new Set([...vehicleId, ...vehicleId2]).size) {
            flag = false;
          }
        } else {
          flag = false;
        }
      }

      return flag;
    },
    showSumResult(item, ind, ind2) {
      let flag = 0;
      if (item && item.sumResult) {
        if (this.searchParams.vehicleId && item.vehicleIds) {
          let vehicleId = this.searchParams.vehicleId.split(',');
          let vehicleId2 = item.vehicleIds.split(',');
          let length = vehicleId.length + vehicleId2.length;
          let length2 = new Set([...vehicleId, ...vehicleId2]).size;
          if (length > length2) {
            flag = length - length2;
          }
        } else {
          flag = item.sumResult;
        }
      };
      return flag;
    },
    showAllSumResult(item) {
      let num = 0;
      item.list.map(val => {
        if (val.label != '全部') {
          num += this.showSumResult(val);
        }
      });
      return num;
    }
  }
}
</script>

<style lang="scss">
.form {
  padding: 24px;
  background-color: #fff;
  border-radius: 8px 8px 0px 0px;
  .filte-box {
    display: flex;
    line-height: 24px;
    margin-bottom: 10px;
    > .box-load {
      width: 100px;
      margin-right: 12px;
      color: #666;
      font-size: 12px;
    }
    > .box-conten {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      > div {
        margin-bottom: 8px;
        margin-right: 24px;
        padding: 0 12px;
        border-radius: 6px;
        color: #666;
        border: 1px solid #4278c9;
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      > .check-options {
        background-color: rgba(66, 120, 201, 1);
        border: 1px solid rgba(66, 120, 201, 1);
        color: #fff;
      }
      > .disabled {
        border: 1px solid #a0cfff;
        color: #c0c4cc;
      }
    }
  }
}
</style>
<template>
  <div class="kit">
      <div class="kit-left" ref="kitLeft">
          <div class="kit-header">
              <Iconfont :name="iconName" :size="70" color="rgba(66, 120, 201, 1)" />
              <div class="title">{{ title }}</div>
          </div>
          <div :class="{'kit-left-content': true, 'selected': ind == componentsInd }" v-for="(item, ind) in contentList" :key="ind" @click="handleGoComponemts(ind, item.path)">
              <Iconfont :name="item.iconName" :size="26" color="rgba(66, 120, 201, 1)" />
              <span class="kit-left-c">{{ item.title }}</span>
          </div>
      </div>
      <div class="kit-right" :style="{ maxHeight: maxH+'px' }">
          <slot name="form" ref="form-box"></slot>
          <div style="flex: 1">
              <slot name="route"></slot>
          </div>
      </div>
  </div>
</template>

<script>
import Iconfont from "@/components/Icon/Iconfont";
// import Form from "@/components/RouterKit/components/Form.vue"
export default {
    components: {
        Iconfont,
        // Form
    },
    props: {
        contentList: {
            type: Array,
            default: function() {
                return []
            }
        },
        title: {
            type: String,
            default: ''
        },
        iconName: {
          type: String,
          default: 'icon-gongshitongji1'
        }
    },
    data() {
        return {
            componentsInd: 0,
            maxH: 0,
            fullscreenchange: null
        }
    },
    mounted() {
      const that = this;
      this.maxH = this.$refs.kitLeft.offsetHeight;
      // 监听放大页面
      this.fullscreenchange = addEventListener('fullscreenchange', event => {
        that.$nextTick(()=>{
          that.getMaxHeight();
        })
      });
    },
  // destroyed
  deactivated() {
    this.fullscreenchange ? removeEventListener(this.fullscreenchange) : '';
    this.fullscreenchange = null;
  },
    methods:{
        handleGoComponemts(ind, path) {
            this.componentsInd = ind;
            this.$emit("change", path)
        },
        getMaxHeight() {
          this.maxH = this.$refs.kitLeft.offsetHeight;
        }
    }
}
</script>

<style lang="scss" scoped>
.kit {
    display: flex;
    min-height: 100%;
    >.kit-left {
        width: 240px;
        margin-right: 10px;
        background-color: #fff;
        border-radius: 0 10px 0 0;
        >.kit-header {
            text-align: center;
            margin-top: 45px;
            margin-bottom: 32px;
            >.title {
                font-size: 16px;
                color: #333;
                margin-top: -18px;
            }
        }
        >.kit-left-content {
            position: relative;
            cursor: pointer;
            color: #333;
            height: 36px;
            line-height: 36px;
            display: flex;
            justify-content: center;
            padding-right: 48px;
            >.kit-left-c {
                margin-left: 6px;
            }
        }
        .selected {
            background-color: rgba(220, 232, 252, 1);
            color: rgba(66, 120, 201, 1);
        }
        .selected::after {
            content: "";
            position: absolute;
            right: 0;
            top: 6px;
            width: 4px;
            height: 24px;
            border-radius: 8px;
            background-color: rgba(66, 120, 201, 1);
        }
    }
    >.kit-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-right: 10px;
        overflow: auto;
    }
}
</style>